<template>
  <div class="px-2 mt-1">
    <header-slot>
      <template #actionsButtons>
        <portal-target name="actionsButtons_header" />
      </template>
    </header-slot>
    <b-nav card-header pills class="m-0">
      <b-nav-item
        :active="isActive"
        exact
        exact-active-class="active"
        :link-classes="['px-3', bgTabsNavs]"
        @click="() => $router.push({ name: 'manual' })"
      >
        NCR PROVIDERS
      </b-nav-item>
      <b-nav-item
        v-if="isCeo || isSupervisor"
        :to="{ name: 'questionnaire' }"
        exact
        exact-active-class="active"
        :link-classes="['px-3', bgTabsNavs]"
      >
        QUESTIONNAIRE
      </b-nav-item>
      <b-nav-item
        v-if="isCeo || isSupervisor"
        :to="{ name: 'spfa-settings-commissions' }"
        exact
        :active="isCommissionTab"
        exact-active-class="active"
        :link-classes="['px-3', bgTabsNavs]"
      >
        SERVICES
      </b-nav-item>
    </b-nav>

    <b-card class="border-top-primary border-3 border-table-radius px-0">
      <template v-if="isActive">
        <b-nav card-header pills class="m-0">
          <b-nav-item
            :to="{ name: 'manual' }"
            exact
            active-class="bg-info box-shadow-info border-info info"
            exact-active-class="active"
            :link-classes="['sub-tab px-3', bgTabsNavs]"
          >
            MANUAL
          </b-nav-item>

          <b-nav-item
            :to="{ name: 'automatic' }"
            exact
            active-class="bg-info box-shadow-info border-info info"
            exact-active-class="active"
            :link-classes="[bgTabsNavs, 'sub-tab px-3']"
          >
            AUTOMATIC
          </b-nav-item>
        </b-nav>
        <b-card
          no-body
          class="border-top-info border-3 border-table-radius px-0"
        >
        </b-card>
      </template>
      <router-view ref="routerViewTab" :key="$route.name" />
    </b-card>
  </div>
</template>

<script>
export default {
  computed: {
    isActive() {
      const routes = ["automatic", "manual"];
      return routes.includes(this.$route.name);
    },
    moduleId() {
      return this.$route.matched[0].meta.module;
    },
    isCommissionTab() {
      const { routeCommissionList, routeCommissionRequest } =
        this.$route.matched[2].meta;
      return [routeCommissionList, routeCommissionRequest].includes(
        this.$route.name
      );
    },
  },
};
</script>
